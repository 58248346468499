import React from "react";
import { Helmet } from "react-helmet";
import styles from "./Kontakt.module.scss";

const Kontakt = () => (
  <div className={styles.container}>
    <Helmet>
      <meta charSet="utf-8" name="description" content="Kontakta CC Wool" />
      <title>CC Wool | Kontakt</title>
      <link rel="canonical" href="https://www.ccwool.se/kontakt" />
    </Helmet>
    <span className={styles.title}>Kontakt</span>
    <span className={styles.sub}>Ta kontakt med oss idag</span>
    <div>
      Mejl:{" "}
      <a className={styles.link} href="mailto:info@ccwool.se">
        info@ccwool.se
      </a>
    </div>
    <div>
      Telefon:{" "}
      <a className={styles.link} href="tel:+46760941233">
        0760941233
      </a>
    </div>
  </div>
);

export default Kontakt;
