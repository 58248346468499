import React, { useRef, useState } from 'react';
import styles from './Header.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Icon, Pane, Popover } from 'evergreen-ui';
const ccLogo = require('../../assets/img/logo.png');

const Header = () => {
  const SHOP_URL = 'https://shop.ccwool.se'
  const [isOpen, setIsOpen] = useState(false);

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const scrollToTop = () => window.scrollTo(0, 0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 875px)' });
  const myRef = useRef(null);

  function executeScroll() {
    scrollToRef(myRef);
    setIsOpen(false);
  }

  function scrollTop() {
    scrollToTop();
    setIsOpen(false);
  }

  const popoverContent = (
    <Pane width={240} display="flex" flexDirection="column" padding="20px">
      <Link to="/" className={styles.popoverLink} onClick={scrollTop}>
        Startsidan
      </Link>
      <Link
        to="/svensk-ull"
        className={styles.popoverLink}
        onClick={executeScroll}
      >
        Svensk Ull
      </Link>
      <Link
        to="/skotselrad"
        className={styles.popoverLink}
        onClick={executeScroll}
      >
        Skötselråd
      </Link>
      <Link to="/om-oss" className={styles.popoverLink} onClick={executeScroll}>
        Om oss
      </Link>
      <Link
        to="/kontakt"
        className={styles.popoverLink}
        onClick={executeScroll}
      >
        Kontakta
      </Link>
      <a rel="noopener noreferrer" href={SHOP_URL} target="_blank" className={styles.popoverLink} onClick={executeScroll}>
        <Icon style={{ marginRight: 5 }} size={16} icon="shopping-cart" /> Butik
      </a>
    </Pane>
  );

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={styles.inner}>
          <div className={styles.navbar}>
            <Link to="/" className={styles.logo}>
              <img src={ccLogo} width={150} alt={'ccwool logo'} />
            </Link>
            {isTabletOrMobile ? (
              <Popover
                content={popoverContent}
                shouldCloseOnExternalClick={true}
                onOpen={() => setIsOpen(true)}
                isShown={isOpen}
                onClose={() => setIsOpen(false)}
              >
                <Icon size={16} icon="menu" color={'#333'} />
              </Popover>
            ) : (
              <div className={styles.linksContainer}>
                <Link to="/" className={styles.link} onClick={scrollTop}>
                  Startsidan
                </Link>
                <Link
                  to="/svensk-ull"
                  className={styles.link}
                  onClick={executeScroll}
                >
                  Svensk Ull
                </Link>
                <Link
                  to="/skotselrad"
                  className={styles.link}
                  onClick={executeScroll}
                >
                  Skötselråd
                </Link>
                <Link
                  to="/om-oss"
                  className={styles.link}
                  onClick={executeScroll}
                >
                  Om oss
                </Link>
                <Link
                  to="/kontakt"
                  className={styles.link}
                  onClick={executeScroll}
                >
                  Kontakta
                </Link>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={SHOP_URL}
                  className={styles.link}
                >
                  <Icon
                    style={{ margin: '0 5px 0 5px' }}
                    size={16}
                    icon="shopping-cart"
                  />{' '}
                  Butik
                </a>
              </div>
            )}
          </div>
        </div>
        <div className={styles.overlayContentContainer}>
          <div className={styles.overlayContent}>
            <span className={styles.title}>
              En miljövänlig och hållbar produktserie av svensk ull.
            </span>
            <span className={styles.sub}>
              För att bidra till ett långsiktigt hållbart samhälle spelar valet
              av råvara in. Svensk ull är en fantastisk, och i dagsläget en
              outnyttjad, råvara. Vi vill vara en del i att ändra på det.
            </span>
            <a rel="noopener noreferrer" ref={myRef} className={styles.button} href={SHOP_URL} target="_blank">
              Till butiken
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
