import React from 'react';
import { Layout } from './components/Layout';
import { Switch, Route } from 'react-router';
import { Home, SvenskUll, Skotselrad, OmOss, Kontakt } from './pages';

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/svensk-ull">
          <SvenskUll />
        </Route>
        <Route path="/skotselrad">
          <Skotselrad />
        </Route>
        <Route path="/om-oss">
          <OmOss />
        </Route>
        <Route path="/kontakt">
          <Kontakt />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
