import React from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./Footer.module.scss";
import { Icon } from "evergreen-ui";
import { Facebook, Instagram } from "../Icon";

const Footer = () => {
  const isSmallPhone = useMediaQuery({ query: "(max-width: 320px)" });

  return (
    <div className={styles.outer}>
      <div className={styles.container}>
        <div className={styles.col}>
          <span className={styles.title}>CC Wool</span>
          <span className={styles.sub}>
            <Icon style={{ marginRight: 5 }} size={16} icon="document" />
            {isSmallPhone
              ? "Orgnummer: 559313-5873"
              : "Organisationsnummer: 559313-5873"}
          </span>
          <span className={styles.sub}>
            <Icon style={{ marginRight: 5 }} size={16} icon="phone" />
            <a href="tel:+(46)760941233">0760941233</a>
          </span>
          <span className={styles.sub}>
            <Icon style={{ marginRight: 5 }} size={16} icon="envelope" />
            <a href="mailto:info@ccwool.se">info@ccwool.se</a>
          </span>
          <span className={styles.sub}>
            <Icon style={{ marginRight: 5 }} size={16} icon="map-marker" />
            Pråmhusvägen 183, 312 97 Laholm
          </span>
        </div>
      </div>
      <div className={styles.social}>
        <a
          href="https://www.facebook.com/ccwool"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: 10 }}
        >
          <Facebook className={styles.facebook} />
        </a>
        <a
          href="https://instagram.com/ccwool_se"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram className={styles.insta} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
