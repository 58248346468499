import React from "react";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";
import styles from "./Skotselrad.module.scss";

const list = [
  "Undvik tvättmaskin.",
  "Häst och hundprodukterna borstas efter användning för att avlägsna hår och smutspartiklar. Låt torka mellan användningar. Vädra produkten ofta, gärna efter varje användning. Ull har förmågan att stöta bort smuts och bryta ner bakterier, vädring av ullprodukter räcker därför gott och väl.",
  "Om produkten ej vädrats på länge kan tvätt behövas, tvätta för hand med ett specialanpassat ulltvättmedel i ljummet vatten. Sträck produkten innan den hängs på tork. Observera att lädret kan missfärga ullen vid tvättning.",
];

const Skotselrad = () => (
  <div className={styles.container}>
    <Helmet>
      <meta
        charSet="utf-8"
        name="description"
        content="Skötselråd för att hålla dina produkter i toppform"
      />
      <title>CC Wool | Skötselråd</title>
      <link rel="canonical" href="https://www.ccwool.se/skotselrad" />
    </Helmet>
    <span className={styles.title}>Skötselråd</span>
    {/* <span className={styles.sub}></span> */}
    <p>Lista på skötselråd: </p>
    <List list={list} />
  </div>
);

export default Skotselrad;
