import React, { FC } from 'react';
import { Header } from '../Header';
import styles from './Layout.module.scss';
import { Footer } from '../Footer';

const Layout: FC = ({ children }) => (
  <>
    <Header />
    <div className={styles.container}>{children}</div>
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  </>
);

export default Layout;
