import React from "react";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";
import styles from "./SvenskUll.module.scss";

const listContent = [
  "Ull andas både i kyla och värme",
  "Ull håller upp till 35% fukt utan att kyla",
  "Ull är nedbrytningsbart",
  "Ull är slitstarkt och formbart",
];

const SvenskUll = () => (
  <div className={styles.container}>
    <Helmet>
      <meta
        charSet="utf-8"
        name="description"
        content="Ullen är en naturlig, närproducerad superfiber som bidrar till ett mer
      hållbart samhälle."
      />
      <title>CC Wool | Svensk ull</title>
      <link rel="canonical" href="https://www.ccwool.se/svensk-ull" />
    </Helmet>
    <span className={styles.title}>Svensk Ull</span>
    <span className={styles.sub}>
      Ullen är en naturlig, närproducerad superfiber som bidrar till ett mer
      hållbart samhälle.
    </span>
    <div className={styles.textContent}>
      <p>Ullen har flera unika egenskaper, bland annat:</p>
      <List list={listContent} />
    </div>
  </div>
);

export default SvenskUll;
