import React from "react";
import { Helmet } from "react-helmet";
import styles from "./OmOss.module.scss";

const OmOss = () => (
  <div className={styles.container}>
    <Helmet>
      <meta
        charSet="utf-8"
        name="description"
        content="Tillsammans grundade Calle och Charlotte CC Wool. Ett företag som
      kombinerar deras passion för fåren, hästen och miljön."
      />
      <title>CC Wool | Om oss</title>
      <link rel="canonical" href="https://www.ccwool.se/om-oss" />
    </Helmet>
    <span className={styles.title}>Om oss</span>
    <span className={styles.sub}>
      Tillsammans grundade Calle och Charlotte CC Wool. Ett företag som
      kombinerar deras passion för fåren, hästen och miljön.
    </span>
    <div>
      <p>
        Calle är fårbonde, fårklippare och en drivande entreprenör som jobbat
        med får sen 2005. Intresset för fåren har tagit Calle runt världen,
        bland annat till Norge, Nya Zeeland och England. Hemma på gården finns
        180 tackor och två ovärderliga vallhundar. Calle har även titeln Svensk
        Mästare i Fårklippning på meritlistan.
      </p>
      <p style={{ marginBottom: 50 }}>
        Charlotte är hästtjej sen barnsben som har förverkligat sina drömmar om
        att jobba med häst till vardags. Eter gymnasiet flyttade hon till
        Australien där hon bland annat jobbade som ridlärare och boskapsfösare.
        Charlotte har senare läst vidare och har idag en Master i Equine
        Science.
      </p>
    </div>
  </div>
);

export default OmOss;
