import React from "react";
import { Helmet } from "react-helmet";
import styles from "./Home.module.scss";

const Home = () => (
  <div className={styles.container}>
    <Helmet>
      <meta
        charSet="utf-8"
        name="description"
        content="CC Wool's mål är att producera användbara och hållbara produkter utan
        syntetiska fibrer och långa transporter. Vi vill istället basera våra
        produkter på ullfibrer som produceras på Svenska gårdar med hög
        djurvälfärd. Våra produkter ska inte bara vara snygga utan ska
        framförallt funktionella och användbara."
      />
      <title>CC Wool</title>
      <link rel="canonical" href="https://www.ccwool.se" />
    </Helmet>
    <span className={styles.title}>Vår vision</span>
    <span className={styles.sub}>
      Vi vill skapa en miljövänlig, hållbar produktserie av svensk ull.
    </span>
    <div className={styles.textContent}>
      <p>
        CC Wool's mål är att producera användbara och hållbara produkter utan
        syntetiska fibrer och långa transporter. Vi vill istället basera våra
        produkter på ullfibrer som produceras på Svenska gårdar med hög
        djurvälfärd. Våra produkter ska inte bara vara snygga utan ska
        framförallt funktionella och användbara.
      </p>
      <p>
        För att bidra till ett långsiktigt hållbart samhälle spelar valet av
        råvara in. Svensk ull är en fantastisk, och i dagsläget en outnyttjad,
        råvara. Vi vill vara en del i att ändra på det, av den anledningen
        grundades CC Wool.
      </p>
      <p style={{ marginBottom: 50 }}>
        Våra produkter tillverkas i Sverige och läderdetaljerna på produkterna
        är läder från halländska tackor.
      </p>
    </div>
  </div>
);

export default Home;
