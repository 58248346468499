import React, { FC } from 'react';
import styles from './List.module.scss';

interface Props {
  list: string[];
}

const List: FC<Props> = ({ list }) => (
  <div className={styles.container}>
    {list.map((item, index) => (
      <div key={index} className={styles.listItem}>
        {item}
      </div>
    ))}
  </div>
);

export default List;
